import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {ru},
    current: 'ru'
  },
  theme: {
    themes: {
      dark: {
        primary: '#D4C304',
      },
      light: {
        primary: '#D4C304',
      },
    },
  },
});
