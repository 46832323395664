<template>
  <v-footer
    color="#D4C304"
    app flat
    min-height="0px"
    :absolute="true "
    depressed style="position: fixed; background: rgb(246, 246, 246); border-top: 2px solid #D4C304; padding: 0;"
  >

    <!--div>
      <a
        :href="'/static/lk/docs/Правила_партнерской_программы.pdf'"
        target="_blank" class="doc-link"
      >Правила партнерской программы
      </a>
      <br/>
      <a
        :href="'/static/lk/docs/Соглашение_об_использовании_АСП.pdf'"
        target="_blank" class="doc-link"
      >Соглашение об использовании АСП
      </a>
    </div>
    <v-spacer/>
    <div>
      <a :href="'tel:88005505235'" style="text-decoration: none; color: inherit;">
        <b>8 (800) 550-52-35</b>
      </a>
      <span> звонки по всей России</span>
    </div-->

  <div class="foot_menu">
    <a class="fm_link" href="/lk/"><img src="/static/lk/img/home.svg">Главный</a>
    <a class="fm_link" href="/lk/applications/"><img src="/static/lk/img/two_sheets.svg">Заявки</a>
    <a class="fm_link" href="/lk/operations/"><img src="/static/lk/img/wallet.svg">Кошелёк</a>
    <a class="fm_link" href="/lk/personal/"><img src="/static/lk/img/user.svg">Профиль</a>
    <!--a class="fm_link" href="/lk/agreements/"><img src="/static/lk/img/sheet.svg">Реквизиты</a-->
    <!--a class="fm_link" href="/lk/operations/"><img src="/static/lk/img/clock.svg">Платежи</a-->
  </div>

  </v-footer>

</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "AppFooter",
  computed: {
    ...mapState(['agent']),
  }
}
</script>

<style scoped>
.doc-link {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
.doc-link:hover {
  text-decoration: underline;
}

.foot_menu { display: flex; justify-content: space-around; padding: 15px 15px; width: 100%; }
.fm_link { font-size: 12px; text-decoration: none; color: #000; line-height: 1; display: flex; flex-direction: column; align-items: center; }
.fm_link img { height: 22px; margin-bottom: 5px; }

@media screen and (min-width: 600px) {
  .foot_menu {
    justify-content: center;
    gap: 40px;
  }
}
</style>