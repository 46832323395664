<template>
  <v-app-bar
    color="#D4C304"
    app flat
    height="80px"
    depressed style="background: #D4C304"
  >
    <a href="/">
      <div
        class="app-bar-logo"
        :style="{'background-image': 'url(/static/lk/img/logo_2.svg)'}"
      />
    </a>
    <v-spacer/>
    <template v-if="agent">
      <div class="header_say_hi">
        <span>{{agent.firstName}},</span>
        добро пожаловать<br />
        в личный кабинет агента
      </div>
      <v-btn
        depressed style="background: #fff; padding: 0; margin-right: 15px; height: 40px; width: 40px; min-width: 0; border-radius: 10px;"
        href="/lk/personal/"
      >
        <img src="/static/lk/img/user.svg">
      </v-btn>
      <v-btn
        depressed style="background: transparent; padding: 0; height: 40px; width: 22px; min-width: 0;"
        href="/logout/"
      >
        <img src="/static/lk/img/exit.svg">
      </v-btn>
    </template>
    <template v-else>
      <v-btn
        depressed style="color: #D4C304"
        href="/login/"
      >
        Вход
      </v-btn>
      <v-btn
        class="ml-4"
        depressed style="color: #D4C304"
        href="/registration/"
      >
        Стать партнером
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "AppBar",
  computed: {
    ...mapState(['agent']),
  }
}
</script>

<style scoped>
.app-bar-logo {
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  height: 80px;
  width: 75px;
  position: absolute;
  top: 0;
  left: 0;
}
.header_say_hi { line-height: 1; font-size: 12px; text-align: right; margin-right: 15px; }
.header_say_hi span { font-size: 16px; font-weight: 600; display: block; margin-bottom: 5px; }
</style>