import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'landing',
    component: () => import('../views/Landing'),
  },
  {
    path: '/lk/',
    name: 'lk',
    component: () => import('../components/Lk'),
    children: [{
      path: '',
      name: 'home',
      component: () => import('../views/Home'),
    }, {
      path: 'personal/',
      name: 'personal',
      component: () => import('../views/Personal'),
    }, {
      path: 'agreements/:id/',
      name: 'agreementDetail',
      component: () => import('../views/AgreementDetail'),
    }, {
      path: 'applications/',
      name: 'applications',
      component: () => import('../views/Applications'),
    }, {
      path: 'operations/',
      name: 'operations',
      component: () => import('../views/Operations.vue'),
    }],
  },
  {
    path: '/login/',
    name: 'login',
    component: () => import('../views/Login'),
  },
  {
    path: '/registration/',
    name: 'registration',
    component: () => import('../views/Reg'),
  },
  {
    path: '/restore_password/',
    name: 'restore_password',
    component: () => import('../views/RestorePassword'),
  },
]


const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
